import { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import ChatManagement from "../features/chat-management/ChatManagement";
import { Button } from "@/components/ui/button";
import {
  PlayCircle,
  BookOpen,
  Settings,
  History,
  Info,
  Building2,
  FileText,
  Upload,
  Plus,
  Globe,
  Newspaper,
  MessageSquare,
  ArrowRight,
  LogOut,
} from "lucide-react";
import OnboardingVideo from "@/shared/components/onboarding-video/OnboardingVideo";
import SettingsModal from "../features/header/components/Settings";
import { useGetPlaybooks } from "../features/playbook/api/useGetPlaybooks";
import { PlaybookCardv2 } from "../features/playbook/components/PlaybookCardv2";
import { Playbook, PlaybookShort } from "../features/playbook/model/Playbook";
import { CompanySearchOverlay } from "../features/chat-management/components/CompanySearchOverlay";
import { DocumentSearchOverlay } from "../features/chat-management/components/DocumentSearchOverlay";
import { UploadOverlay } from "../features/chat-management/components/UploadOverlay";
import { PlaybookSelectionModal } from "../features/playbook/components/PlaybookSelectionModal";
import { ResearchQuestionModal } from "../features/chat-management/components/ResearchQuestionModal";
import { ResearchTypeModal } from "../features/chat-management/components/ResearchTypeModal";
import { useCreatePlaybookChat } from "../features/playbook/api/useCreatePlaybookChat";
import { Switch } from "@/components/ui/switch";
import { CompanyInfo } from "@/shared/components/company-info/models/CompanyInfo";
import { CompanyDocumentView } from "@/shared/models/CompanyDocument";
import { CompanyDocumentType } from "@/shared/enums/CompanyDocumentType";
import { useGetUser } from "../features/header/api/useGetUser";
import { useOnboardUser } from "../features/header/api/useOnboardUser";
import { AddContextModal } from "../features/chat-management/components/AddContextModal";
import { useAuth0 } from "@auth0/auth0-react";
import ChatContext from "@/shared/models/ChatContext";
import UploadItem from "@/shared/models/UploadItem";

export default function Home() {
  const navigate = useNavigate();
  const [videoOpened, setVideoOpened] = useState(false);
  const [settingsOpened, setSettingsOpened] = useState(false);
  const { playbooks, getPlaybooks } = useGetPlaybooks();
  const { user, getUser } = useGetUser();
  const { onboardUser, isUserOnboarding } = useOnboardUser();
  const { logout } = useAuth0();

  // New Research states
  const [showContext, setShowContext] = useState(false);
  const [showPlaybookSelection, setShowPlaybookSelection] = useState(false);
  const [showResearchQuestion, setShowResearchQuestion] = useState(false);
  const [showResearchType, setShowResearchType] = useState(false);
  const [context, setContext] = useState<ChatContext>(new ChatContext());
  const { createChat, chat } = useCreatePlaybookChat();
  const [isCompanySearching, setIsCompanySearching] = useState(false);
  const [isDocumentSearching, setIsDocumentSearching] = useState(false);
  const [isUploading, setIsUploading] = useState(false);
  const [selectedCompanies, setSelectedCompanies] = useState<CompanyInfo[]>([]);
  const [selectedDocuments, setSelectedDocuments] = useState<
    CompanyDocumentView[]
  >([]);
  const [uploadedItems, setUploadedItems] = useState<UploadItem[]>([]);
  const [includeNews, setIncludeNews] = useState(true);
  const [includeWeb, setIncludeWeb] = useState(true);
  const [selectedPlaybook, setSelectedPlaybook] = useState<
    Playbook | PlaybookShort
  >();
  const [isSelectedPlaybookDefault, setIsSelectedPlaybookDefault] =
    useState(false);

  // Fetch playbooks on component mount
  useEffect(() => {
    getPlaybooks(true);
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (!chat) return;
    navigate(`/c/${chat.id}`);
  }, [chat, navigate]);

  // Fetch user data on component mount
  useEffect(() => {
    getUser();
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  // Show onboarding video for new users
  useEffect(() => {
    if (user && !user?.hasOnboarded && !isUserOnboarding) {
      setVideoOpened(true);
      onboardUser().then(() => getUser());
    }
  }, [user?.hasOnboarded, user]); // eslint-disable-line react-hooks/exhaustive-deps

  // Get top 4 playbooks
  const topPlaybooks = playbooks?.slice(0, 4) || [];

  const handlePlaybookSelect = (
    playbook: Playbook | PlaybookShort,
    isDefault: boolean,
  ) => {
    setSelectedPlaybook(playbook);
    setIsSelectedPlaybookDefault(isDefault);
    if (context.isEmpty) {
      setShowContext(true);
    } else {
      handleContextComplete(playbook, context);
    }
  };

  const handleContextComplete = async (
    playbook: Playbook | PlaybookShort,
    contextData: ChatContext,
  ) => {
    try {
      const filingIds =
        contextData.documents
          ?.filter(
            (d: CompanyDocumentView) => d.type === CompanyDocumentType.Filing,
          )
          .map((d: CompanyDocumentView) => d.id) || [];
      const transcriptIds =
        contextData.documents
          ?.filter(
            (d: CompanyDocumentView) =>
              d.type === CompanyDocumentType.Transcript,
          )
          .map((d: CompanyDocumentView) => d.id) || [];
      const files =
        contextData.uploadedItems
          ?.filter((i: UploadItem) => i.type === "file" && i.file)
          ?.map((i: UploadItem) => i.file as File) || [];
      const urls =
        contextData.uploadedItems
          ?.filter((i: UploadItem) => i.type === "url" && i.url)
          ?.map((i: UploadItem) => i.url as string) || [];

      await createChat(
        playbook.id,
        isSelectedPlaybookDefault,
        playbook.title,
        false,
        contextData.companies?.map((c: CompanyInfo) => c.id),
        filingIds,
        transcriptIds,
        files,
        contextData.includeNews,
        contextData.includeWeb,
        urls,
      );

      // Reset state after successful chat creation
      setShowContext(false);
      setSelectedPlaybook(undefined);
      setIsSelectedPlaybookDefault(false);
    } catch (error) {
      console.error("Failed to create playbook chat:", error);
    }
  };

  const handleResearchTypeSelect = (type: "chat" | "playbook") => {
    setShowResearchType(false);
    if (type === "playbook") {
      setShowPlaybookSelection(true);
    } else {
      setShowResearchQuestion(true);
    }
  };

  const addCompany = (company: CompanyInfo) => {
    if (!selectedCompanies.find((c) => c.id === company.id)) {
      setSelectedCompanies([...selectedCompanies, company]);
    }
  };

  const removeCompany = (companyId: number) => {
    setSelectedCompanies(selectedCompanies.filter((c) => c.id !== companyId));
  };

  const addDocument = (document: CompanyDocumentView) => {
    if (!selectedDocuments.find((d) => d.id === document.id)) {
      setSelectedDocuments([...selectedDocuments, document]);
    }
  };

  const removeDocument = (documentId: string) => {
    setSelectedDocuments(selectedDocuments.filter((d) => d.id !== documentId));
  };

  const addUploadItem = (item: UploadItem) => {
    setUploadedItems((prev) => {
      const updatedItems = prev.filter((i) => i.id !== item.id);
      return [...updatedItems, item];
    });
  };

  const removeUploadItem = (itemId: number) => {
    setUploadedItems((items) => items.filter((i) => i.id !== itemId));
  };

  const handleComplete = () => {
    const contextData = new ChatContext();
    contextData.companies = selectedCompanies;
    contextData.documents = selectedDocuments;
    contextData.uploadedItems = uploadedItems;
    contextData.includeNews = includeNews;
    contextData.includeWeb = includeWeb;
    setContext(contextData);
    setShowContext(false);
    setShowResearchType(true);
  };

  return (
    <div className="min-h-screen bg-gradient-to-b from-gray-50 to-white">
      {/* Hero Section */}
      <div className="bg-white border-b border-gray-100">
        <div className="container mx-auto max-w-7xl px-8 py-6">
          <div className="flex justify-between items-center">
            <div>
              <div className="flex items-center gap-2">
                <a href="/">
                  <img
                    src="/images/fullLogoDark.svg"
                    alt="Quantly Logo"
                    className="h-6 w-auto"
                  />
                </a>
              </div>
              <p className="text-gray-500 text-base">
                AI-powered investment research assistant
              </p>
            </div>
            <div className="flex items-center gap-2">
              <Button
                variant="outline"
                size="sm"
                className="text-gray-600 gap-1.5 text-sm h-8"
                onClick={() => setVideoOpened(true)}
              >
                <PlayCircle className="h-3.5 w-3.5" />
                See Tutorial
              </Button>
              <Button
                variant="outline"
                size="sm"
                className="text-gray-600 gap-1.5 text-sm h-8"
                onClick={() => navigate("/playbooks")}
              >
                <BookOpen className="h-3.5 w-3.5" />
                Playbook Manager
              </Button>
              <Button
                variant="outline"
                size="sm"
                className="text-gray-600 gap-1.5 text-sm h-8"
                onClick={() => setSettingsOpened(true)}
              >
                <Settings className="h-3.5 w-3.5" />
                Settings
              </Button>
              <Button
                variant="outline"
                size="sm"
                className="text-gray-600 gap-1.5 text-sm h-8"
                onClick={() =>
                  logout({ logoutParams: { returnTo: window.location.origin } })
                }
              >
                <LogOut className="h-3.5 w-3.5" />
                Log out
              </Button>
            </div>
          </div>
        </div>
      </div>

      {/* Main Content */}
      <div className="container mx-auto max-w-7xl px-8 py-6">
        <div className="flex flex-col gap-8">
          {/* New Research Section */}
          <section className="bg-white rounded-xl border shadow-sm">
            <div className="p-6 border-b">
              <div className="flex items-center gap-3">
                <div className="bg-gray-100 p-2 rounded-lg">
                  <MessageSquare className="h-6 w-6 text-gray-900" />
                </div>
                <h2 className="text-2xl font-semibold text-gray-800">
                  New Research
                </h2>
              </div>
            </div>

            <div className="p-6 space-y-6">
              <div className="bg-amber-50 border border-amber-200 rounded-lg p-3 flex items-start gap-3">
                <div className="text-amber-500 mt-0.5">
                  <Info className="h-5 w-5" />
                </div>
                <p className="text-sm text-amber-800 font-medium">
                  You can add multiple items to each category and combine
                  categories and sources.
                </p>
              </div>

              {/* Main Upload Options */}
              <div className="grid grid-cols-1 md:grid-cols-3 gap-4">
                {/* Companies Section */}
                <div className="group relative">
                  <Button
                    variant="outline"
                    className="w-full h-auto p-6 hover:border-blue-500 hover:bg-blue-50/50
                             flex flex-col items-center gap-4 group-hover:border-blue-500
                             min-h-[200px] relative"
                    onClick={() => setIsCompanySearching(true)}
                  >
                    <Building2 className="h-10 w-10 text-gray-600 group-hover:text-blue-600" />
                    <div className="text-center">
                      <h3 className="font-medium mb-2 text-lg">
                        Add Companies
                      </h3>
                      <p className="text-sm text-gray-500">
                        Include one or multiple companies to research
                      </p>
                    </div>
                    {selectedCompanies.length > 0 && (
                      <div className="absolute top-2 right-2 bg-blue-100 text-blue-700 px-2 py-1 rounded-full text-xs">
                        {selectedCompanies.length} added
                      </div>
                    )}
                    <Plus className="h-8 w-8 absolute bottom-4 right-4 text-gray-400 group-hover:text-blue-500 transition-all duration-200 group-hover:scale-110" />
                  </Button>
                </div>

                {/* Documents Section */}
                <div className="group relative">
                  <Button
                    variant="outline"
                    className="w-full h-auto p-6 hover:border-emerald-500 hover:bg-emerald-50/50
                             flex flex-col items-center gap-4 group-hover:border-emerald-500
                             min-h-[200px] relative"
                    onClick={() => setIsDocumentSearching(true)}
                  >
                    <FileText className="h-10 w-10 text-gray-600 group-hover:text-emerald-600" />
                    <div className="text-center">
                      <h3 className="font-medium mb-2 text-lg">
                        Add Specific Documents
                      </h3>
                      <p className="text-sm text-gray-500">
                        Include specific documents you want to research
                      </p>
                    </div>
                    {selectedDocuments.length > 0 && (
                      <div className="absolute top-2 right-2 bg-emerald-100 text-emerald-700 px-2 py-1 rounded-full text-xs">
                        {selectedDocuments.length} added
                      </div>
                    )}
                    <Plus className="h-8 w-8 absolute bottom-4 right-4 text-gray-400 group-hover:text-emerald-500 transition-all duration-200 group-hover:scale-110" />
                  </Button>
                </div>

                {/* Upload Section */}
                <div className="group relative">
                  <Button
                    variant="outline"
                    className="w-full h-auto p-6 hover:border-purple-500 hover:bg-purple-50/50
                             flex flex-col items-center gap-4 group-hover:border-purple-500
                             min-h-[200px] relative"
                    onClick={() => setIsUploading(true)}
                  >
                    <Upload className="h-10 w-10 text-gray-600 group-hover:text-purple-600" />
                    <div className="text-center">
                      <h3 className="font-medium mb-2 text-lg">
                        Upload Files or URLs
                      </h3>
                      <p className="text-sm text-gray-500">
                        Upload files or add URLs to include in research
                      </p>
                    </div>
                    {uploadedItems.length > 0 && (
                      <div className="absolute top-2 right-2 bg-purple-100 text-purple-700 px-2 py-1 rounded-full text-xs">
                        {uploadedItems.length} added
                      </div>
                    )}
                    <Plus className="h-8 w-8 absolute bottom-4 right-4 text-gray-400 group-hover:text-purple-500 transition-all duration-200 group-hover:scale-110" />
                  </Button>
                </div>
              </div>

              {/* Additional Sources */}
              <div className="space-y-4">
                <h3 className="text-base font-medium text-gray-900">
                  Additional Sources
                </h3>
                <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
                  {/* News Card */}
                  <button
                    onClick={() => setIncludeNews(!includeNews)}
                    className={`w-full transition-colors 
                             ${includeNews ? "bg-emerald-50 border-emerald-200" : "bg-gray-50 border-gray-200"} 
                             border-2 rounded-lg p-4 hover:bg-emerald-50/70 group`}
                  >
                    <div className="flex items-center justify-between">
                      <div className="flex items-center gap-3">
                        <div
                          className={`p-2 rounded-lg ${includeNews ? "bg-emerald-100" : "bg-gray-100"}`}
                        >
                          <Newspaper
                            className={`h-5 w-5 ${includeNews ? "text-emerald-600" : "text-gray-600"}`}
                          />
                        </div>
                        <div className="text-left">
                          <h3
                            className={`font-medium ${includeNews ? "text-emerald-900" : "text-gray-900"}`}
                          >
                            Include News
                          </h3>
                          <p
                            className={`text-sm ${includeNews ? "text-emerald-600" : "text-gray-600"}`}
                          >
                            News articles will be included
                          </p>
                        </div>
                      </div>
                      <Switch
                        checked={includeNews}
                        onCheckedChange={setIncludeNews}
                        className={includeNews ? "bg-emerald-500" : ""}
                      />
                    </div>
                  </button>

                  {/* Web Search Card */}
                  <button
                    onClick={() => setIncludeWeb(!includeWeb)}
                    className={`w-full transition-colors 
                             ${includeWeb ? "bg-blue-50 border-blue-200" : "bg-gray-50 border-gray-200"} 
                             border-2 rounded-lg p-4 hover:bg-blue-50/70 group`}
                  >
                    <div className="flex items-center justify-between">
                      <div className="flex items-center gap-3">
                        <div
                          className={`p-2 rounded-lg ${includeWeb ? "bg-blue-100" : "bg-gray-100"}`}
                        >
                          <Globe
                            className={`h-5 w-5 ${includeWeb ? "text-blue-600" : "text-gray-600"}`}
                          />
                        </div>
                        <div className="text-left">
                          <h3
                            className={`font-medium ${includeWeb ? "text-blue-900" : "text-gray-900"}`}
                          >
                            Web Search
                          </h3>
                          <p
                            className={`text-sm ${includeWeb ? "text-blue-600" : "text-gray-600"}`}
                          >
                            Web search results will be included
                          </p>
                        </div>
                      </div>
                      <Switch
                        checked={includeWeb}
                        onCheckedChange={setIncludeWeb}
                        className={includeWeb ? "bg-blue-500" : ""}
                      />
                    </div>
                  </button>
                </div>
              </div>

              {/* Footer */}
              <div className="flex justify-end">
                <Button
                  onClick={handleComplete}
                  className="bg-gray-900 hover:bg-gray-800 text-white"
                >
                  Start Research
                </Button>
              </div>
            </div>
          </section>

          {/* Favorite Playbooks Section */}
          <section className="bg-white rounded-xl border shadow-sm">
            <div className="p-6 border-b">
              <div className="flex items-center justify-between">
                <div className="flex items-center gap-3">
                  <div className="bg-gray-100 p-2 rounded-lg">
                    <BookOpen className="h-6 w-6 text-gray-900" />
                  </div>
                  <h2 className="text-2xl font-semibold text-gray-800">
                    Favorite Playbooks
                  </h2>
                </div>
                <button
                  onClick={() => navigate("/playbooks")}
                  className="text-gray-600 hover:text-gray-900 flex items-center gap-1 text-sm"
                >
                  Go to Playbook Manager
                  <ArrowRight className="h-4 w-4" />
                </button>
              </div>
            </div>

            <div className="p-6">
              <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
                {topPlaybooks.map((playbook) => (
                  <PlaybookCardv2
                    key={playbook.id}
                    playbook={playbook}
                    isDefault={false}
                    setActivePlaybook={(p) => handlePlaybookSelect(p, false)}
                    setIsDefault={setIsSelectedPlaybookDefault}
                  />
                ))}
              </div>
            </div>
          </section>

          {/* Recent Research Section */}
          <section className="bg-white rounded-xl border shadow-sm">
            <div className="p-6 border-b">
              <div className="flex items-center gap-3">
                <div className="bg-gray-100 p-2 rounded-lg">
                  <History className="h-6 w-6 text-gray-900" />
                </div>
                <h2 className="text-2xl font-semibold text-gray-800">
                  Recent Research
                </h2>
              </div>
            </div>

            <div className="bg-gray-50">
              <div className="p-6">
                <ChatManagement />
              </div>
            </div>
          </section>
        </div>
      </div>

      {/* Overlays */}
      <CompanySearchOverlay
        isOpen={isCompanySearching}
        onClose={() => setIsCompanySearching(false)}
        selectedCompanies={selectedCompanies}
        onCompanySelect={addCompany}
        onCompanyRemove={removeCompany}
      />

      <DocumentSearchOverlay
        isOpen={isDocumentSearching}
        onClose={() => setIsDocumentSearching(false)}
        selectedDocuments={selectedDocuments}
        onDocumentSelect={addDocument}
        onDocumentRemove={removeDocument}
      />

      <UploadOverlay
        isOpen={isUploading}
        onClose={() => setIsUploading(false)}
        selectedItems={uploadedItems}
        onItemAdd={addUploadItem}
        onItemRemove={removeUploadItem}
      />

      {/* Modals */}
      <ResearchTypeModal
        open={showResearchType}
        onClose={() => {
          setShowResearchType(false);
          setContext(new ChatContext()); // Reset context if closed
        }}
        onSelectType={handleResearchTypeSelect}
      />

      <PlaybookSelectionModal
        open={showPlaybookSelection}
        onClose={() => {
          setShowPlaybookSelection(false);
          setContext(new ChatContext()); // Reset context if closed
        }}
        onSelect={handlePlaybookSelect}
      />

      <ResearchQuestionModal
        open={showResearchQuestion}
        onClose={() => {
          setShowResearchQuestion(false);
          setContext(new ChatContext()); // Reset context if closed
        }}
        selectedCompanies={context.companies}
        selectedDocuments={context.documents}
        uploadedItems={context.uploadedItems}
        includeNews={context.includeNews}
        includeWeb={context.includeWeb}
        researchType="chat"
      />

      <OnboardingVideo
        opened={videoOpened}
        close={() => setVideoOpened(false)}
      />
      <SettingsModal
        opened={settingsOpened}
        close={() => setSettingsOpened(false)}
      />

      {/* Add Context Modal */}
      {showContext && (
        <AddContextModal
          open={showContext}
          onClose={() => setShowContext(false)}
          onComplete={(contextData) =>
            selectedPlaybook &&
            handleContextComplete(selectedPlaybook, contextData)
          }
          isPlaybook={true}
        />
      )}
    </div>
  );
}
