import { CompanyInfo } from "../components/company-info/models/CompanyInfo";
import { CompanyDocumentView } from "./CompanyDocument";
import UploadItem from "./UploadItem";

export default class Context {
  companies: CompanyInfo[] = [];
  documents: CompanyDocumentView[] = [];
  uploadedItems: UploadItem[] = [];
  includeNews: boolean = true;
  includeWeb: boolean = true;
  urls: string[] = [];

  get isEmpty() {
    return (
      this.companies.length === 0 &&
      this.documents.length === 0 &&
      this.uploadedItems.length === 0 &&
      this.urls.length === 0
    );
  }
}
