import { useApiPrivateRequest } from "../../../shared/hooks/useApi";
import { ChatShort } from "../../../shared/models/Chat";
import {
  EventName,
  EventParameter,
  EventTitle,
  sendEvent,
} from "../../../shared/utils/UsageAnalytics";

export const useCreateCompanyDocsV2Chat = () => {
  const { call, isLoading } = useApiPrivateRequest<ChatShort>((response) => {
    console.log("Mapper received response:", response);
    if (!response) {
      throw new Error("Response is null or undefined");
    }
    const chatShort = new ChatShort(response);
    console.log("Created ChatShort:", chatShort);
    return chatShort;
  });

  const createChat = async (
    input: string,
    companyIds?: number[],
    filingIds?: string[],
    transcriptIds?: string[],
    files?: File[],
    useNews?: boolean,
    useInternet?: boolean,
    urls?: string[],
  ) => {
    const formatData = new FormData();
    formatData.append("Input", input.trim());

    if (companyIds && companyIds.length > 0) {
      companyIds.forEach((id) => {
        formatData.append("CompanyIds", id.toString());
      });
    }

    if (filingIds && filingIds.length > 0) {
      filingIds.forEach((id) => {
        formatData.append("FilingIds", id);
      });
    }

    if (transcriptIds && transcriptIds.length > 0) {
      transcriptIds.forEach((id) => {
        formatData.append("TranscriptIds", id);
      });
    }

    if (files && files.length > 0) {
      files.forEach((file) => {
        formatData.append("Files", file);
      });
    }

    if (urls && urls.length > 0) {
      urls.forEach((url) => {
        formatData.append("Urls", url);
      });
    }

    if (useNews !== undefined) {
      formatData.append("UseNews", useNews.toString());
    }

    if (useInternet !== undefined) {
      formatData.append("UseInternet", useInternet.toString());
    }

    try {
      console.log("Calling API...");
      const response = await call(
        "/chat/companyDocsV2",
        "POST",
        undefined,
        formatData,
      );
      console.log("API Response:", response);

      if (response) {
        sendEvent(EventName.ChatCreated, {
          [EventParameter.Label]: EventTitle.ChatModeCompanyDocsV2,
          [EventParameter.CompanyCount]: (companyIds?.length || 0).toString(),
          [EventParameter.DocumentCount]: (filingIds?.length || 0).toString(),
          [EventParameter.TranscriptCount]: (
            transcriptIds?.length || 0
          ).toString(),
          [EventParameter.FileCount]: (files?.length || 0).toString(),
          [EventParameter.UrlCount]: (urls?.length || 0).toString(),
          [EventParameter.UseNews]: useNews?.toString() || "false",
          [EventParameter.UseInternet]: useInternet?.toString() || "false",
        });
      }

      return response;
    } catch (error) {
      console.error("Failed to create chat:", error);
      throw error;
    }
  };

  return { createChat, isLoading };
};
